import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon32 from "../images/favicon32.png"
import favicon16 from "../images/favicon16.png"
import favicon64 from "../images/favicon64.png"
import socialBanner from "../images/metaImage.jpg"

function SEO({ description, lang, meta, title, image, path }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          image
          siteUrl
        }
      }
    }
  `)

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = site.siteMetadata.siteUrl

  // ✅ Fixed hreflang logic with better handling of `/en/`
  const generateHreflangLinks = [
    {
      hreflang: "nl",
      href: `${siteUrl}${path.startsWith("/en/") ? path.substring(3) : path}`,
    }, // Ensure Dutch is default
    {
      hreflang: "en",
      href: `${siteUrl}${path.startsWith("/en/") ? path : `/en${path}`}`,
    }, // Add /en only if not present
    {
      hreflang: "x-default",
      href: `${siteUrl}${path.startsWith("/en/") ? path.substring(3) : path}`,
    }, // x-default follows Dutch
  ].map(({ hreflang, href }) => ({
    rel: "alternate",
    hreflang,
    href,
  }))

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        { name: "description", content: metaDescription },
        { property: "og:title", content: title },
        { property: "og:description", content: metaDescription },
        { property: "og:type", content: "website" },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: metaDescription },
        { name: "image", content: image || site.siteMetadata.image },
      ].concat(meta)}
      link={[
        { rel: "icon", type: "image/png", sizes: "16x16", href: favicon16 },
        { rel: "icon", type: "image/png", sizes: "32x32", href: favicon32 },
        { rel: "shortcut icon", type: "image/png", href: favicon64 },
        ...generateHreflangLinks,
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  description: "",
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

export default SEO
